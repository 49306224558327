<script setup>
import { ref, computed } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import { procSJAWorkflowAction } from 'sja.vue.libraries.modules.utilities.js'
import { getOrCreateProcedure } from 'o365-modules';
import { alert } from 'o365-vue-services';
import { isMobile } from 'o365.GlobalState.ts';
import { OActionList, OActionItem } from "o365-ui-components";

const props = defineProps({
    actionRow: Object,
    sjaID: Number,
    readOnly: Boolean
});

const procSendActionReminder = getOrCreateProcedure({ id: "procSendActionReminder", procedureName: "astp_SJA_SendTaskEmail" });
let dsResponsible = getDataObjectById(`dsTasksHazardsActionsResp_${props.sjaID}`);
let dsSJA = getDataObjectById(`dsSJA_${props.sjaID}`)
let isHidden = ref(false);

const responsibleData = computed(() => {
    return dsResponsible.data.filter(item => item.SJATasksHazardsAction_ID == props.actionRow.ID);
});

async function createWorkflowAction(responsible, actionRow, stepID) {
    isHidden.value = true;
    try {
        await procSJAWorkflowAction.execute({ Step_ID: stepID, Responsible_ID: responsible.Responsible_ID, ResponsibleRow_ID: responsible.ID, Action_Title: actionRow.Name, Action_Comment: actionRow.Comment });
    }
    finally {
        await dsResponsible.load();
        isHidden.value = false;
    }
}

function sendReminder(person) {
    procSendActionReminder.execute({ SJA_ID: person.SJA_ID, Person_ID: person.Responsible_ID, SJATasksHazardsAction_ID: person.SJATasksHazardsAction_ID, TypeCode: 'SJA_ACTION_REMINDER' })
        .then(() => {
            alert($t('Reminder e-mail has been sent'), 'success', { autohide: true, delay: 5000 });
        })
        .catch((e) => {
            alert($t('An error has occurred, ') + e);
        });
}

</script>

<template>
    <span v-for="(responsible, index) in responsibleData"
        class="card mt-1 d-flex flex-row flex-no-wrap p-1 me-2 align-items-center">
        <span class="me-auto d-inline-block text-truncate" :title="responsible.Name">
            {{ responsible.Name }}
        </span>
        <i v-if="responsible.StepAction_ID" class="ms-auto me-1 bi bi-circle-fill" :title="$t('Workflow action status')" :class="{ 'text-danger': !responsible.Completed, 'text-success': responsible.Completed }"></i>
        <div v-if="!props.readOnly" class="d-flex align-items-center">
            <OActionList v-if="isMobile" mReverseOrder>
                <template #target="{ target }">
                    <a :ref="target" v-bind="$attrs">
                        <button class="d-flex align-items-center btn p-0 px-2 border-0" type="button"
                            data-bs-toggle="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="height: 17px;">
                            <i class="bi bi-chevron-down"></i>
                        </button>
                    </a>
                </template>
                <OActionItem :text="$t('Send reminder e-mail')" @click="sendReminder(responsible)" />
                <OActionItem v-if="!props.readOnly" class="text-danger" :text="$t('Remove person')"
                    @click="responsible.delete();" />
                <OActionItem v-if="!props.readOnly && dsSJA.current.Step_ID && !responsible.StepAction_ID"
                    :text="$t('Create workflow action')"
                    @click="createWorkflowAction(responsible, props.actionRow, dsSJA.current.Step_ID)" />
            </OActionList>

            <div v-else class="ms-auto me-2 fs-4">
                <button class="d-flex align-items-center btn p-0 px-2 border-0" type="button" data-bs-toggle="dropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height: 17px;">
                    <i class="bi bi-chevron-down"></i>
                </button>
                <div class="dropdown-menu z-3" aria-labelledby="dropdownMenuButton" style="line-height: 1;">
                    <button class="dropdown-item" @click="sendReminder(responsible)"
                        :title="$t('Send the responsible a reminder e-mail')">{{ $t("Send reminder e-mail") }}</button>
                    <div v-if="!props.readOnly" :title="$t('Remove person.')" style="-bs-btn-line-height: 1;"
                        class="dropdown-item" @click="responsible.delete();">
                        {{ $t('Remove person') }}
                    </div>
                    <button class="dropdown-item"
                        v-if="!props.readOnly && dsSJA.current.Step_ID && !responsible.StepAction_ID"
                        @click="createWorkflowAction(responsible, props.actionRow, dsSJA.current.Step_ID)">
                        {{ $t('Create workflow action') }}
                    </button>
                </div>
            </div>
        </div>
    </span>
</template>


